/**
 * 工会模块
 */
import request from "@/config/request";

const tag = {
  namespaced: true,
  state: {
    // 主题类型标签
    themeTagList: [],
    // 活动形式标签
    typeTagList: [],
    // 对象标签
    roleTagList: [],
    // 上报数据单位
    dataunitTagList: [],
  },
  mutations: {
    themeTagList(state, themeTagList) {
      state.themeTagList = themeTagList;
    },
    typeTagList(state, typeTagList) {
      state.typeTagList = typeTagList;
    },
    roleTagList(state, roleTagList) {
      state.roleTagList = roleTagList;
    },
    dataunitTagList(state, dataunitTagList) {
      state.dataunitTagList = dataunitTagList;
    },
  },
  actions: {
    async themeTagListAPI({ commit },aid) {
      // const aid = state.association.associationInfo.id
      const data = { aid }
      const res = await request("tag.themeTagList", data)
      commit("themeTagList", res)
    },
    async typeTagListAPI({ commit },aid) {
      // const aid = state.association.associationInfo.id
      const data = { aid }
      const res = await request("tag.formTagList", data)
      commit("typeTagList", res)
    },
    async roleTagListAPI({ commit },aid) {
      // const aid = state.association.associationInfo.id
      const data = { aid }
      const res = await request("tag.roleTagList", data)
      commit("roleTagList", res)
    },
    async dataunitTagListAPI({ commit },aid) {
      // const aid = state.association.associationInfo.id
      const data = { aid }
      const res = await request("tag.belongTagList", data)
      commit("dataunitTagList", res)
    },

  },
};
export default tag;
