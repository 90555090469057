import Mock from "mockjs";
// import "./association";
// import "./instructor";
// import "./lecture";

Mock.setup({
  timeout: "200-600",
});

export default Mock;
